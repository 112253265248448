<div class="text-right">
  <span *ngIf="isSelected" class="checkmark"><button (click)="uncheck()">uncheck</button></span>
  <label class="checkbox-container">
    <input id="XOpposedPosition" type="checkbox">
    <span class="checkmark"></span>
  </label>
  <label class="checkbox-container">
    <input id="YOpposedPosition" type="checkbox">
    <span class="checkmark"></span>
  </label>
  <span (click)="exportToExcel()" title="Export to Excel" class="svg-icon
    svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo8/dist/../src/media/svg/icons/Files/File.svg--><svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
      viewBox="0 0 24 24" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24"/>
          <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2
            14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416
            C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333
            C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22
            C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489
            4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero"
            opacity="0.3"/>
            <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
              <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
              </g>
            </svg><!--end::Svg Icon--></span>

          <span (click)="exportToPng()" title="Export to PNG" class="svg-icon
            svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo8/dist/../src/media/svg/icons/Files/Download.svg--><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
              height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"/>
                  <path d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13
                    C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20
                    L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13
                    C20,12.4477153 20.4477153,12 21,12 C21.5522847,12
                    22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22
                    L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13
                    Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                    <rect fill="#000000" opacity="0.3"
                      transform="translate(12.000000, 8.000000)
                      rotate(-180.000000) translate(-12.000000, -8.000000) "
                      x="11" y="1" width="2" height="14" rx="1"/>
                      <path d="M7.70710678,15.7071068 C7.31658249,16.0976311
                        6.68341751,16.0976311 6.29289322,15.7071068
                        C5.90236893,15.3165825 5.90236893,14.6834175
                        6.29289322,14.2928932 L11.2928932,9.29289322
                        C11.6689749,8.91681153 12.2736364,8.90091039
                        12.6689647,9.25670585 L17.6689647,13.7567059
                        C18.0794748,14.1261649 18.1127532,14.7584547
                        17.7432941,15.1689647 C17.3738351,15.5794748
                        16.7415453,15.6127532 16.3310353,15.2432941
                        L12.0362375,11.3779761 L7.70710678,15.7071068 Z"
                        fill="#000000" fill-rule="nonzero"
                        transform="translate(12.000004, 12.499999)
                        rotate(-180.000000) translate(-12.000004, -12.499999)
                        "/>
                      </g>
                    </svg><!--end::Svg Icon--></span>

                  <span (click)="openHeatmapSettingsModal()" title="Settings"
                    class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo8/dist/../src/media/svg/icons/General/Settings-2.svg--><svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                      height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none"
                        fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                          <path d="M5,8.6862915 L5,5 L8.6862915,5
                            L11.5857864,2.10050506 L14.4852814,5 L19,5
                            L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19
                            L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19
                            L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z
                            M12,15 C13.6568542,15 15,13.6568542 15,12
                            C15,10.3431458 13.6568542,9 12,9 C10.3431458,9
                            9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15
                            Z" fill="#000000"/>
                          </g>
                        </svg><!--end::Svg Icon--></span>
                    </div>
                    <div>
                      <div>
                        <dx-tag-box
                          [(value)]="selectedLabels"
                          [dataSource]="yAxisData"
                          [showSelectionControls]="true"
                          [multiline]="true"
                          [searchEnabled]="true"
                          [showClearButton]="true"
                          [closeOnOutsideClick]="true"
                          (onValueChanged)="filterData()"></dx-tag-box>
                      </div>
                      <div #heatmapContainer>
                 
                        <ejs-heatmap #heatmap id='container'
                          style="display:block;" [showTooltip]='true'
                          [paletteSettings]='paletteSettings'
                          [cellSettings]='cellSettings()'
                          [titleSettings]='settitleSettings()'
                          [dataSource]='dataSourceAux'
                          [xAxis]='xAxis'
                          [yAxis]='yAxisAux'
                          [renderingMode]='renderingMode'
                          [legendSettings]='legendSettings()'
                          (tooltipRender)='tooltipRender($event)'
                          (legendRender)='legendRender($event)'
                          height="{{height}}"
                          (load)='load($event)'
                          [allowSelection]='allowSelection' 
                          (cellSelected)='cellSelected($event)'
                          >
                        </ejs-heatmap>
                      </div>
                     
