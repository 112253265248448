import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface Model {
  id: number;
  name: string;
}

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class SelectionComponent implements OnInit {
  availableModels: Model[] = [];
  selectedModels: Model[] = [];
  availableSelected: Model[] = [];
  selectedSelected: Model[] = [];

  constructor(
    public dialogRef: MatDialogRef<SelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { models: Model[], title: string }
  ) {}

  ngOnInit(): void {
    this.availableModels = [...this.data.models];
  }

  moveToSelected(): void {
    this.selectedModels.push(...this.availableSelected);
    this.availableModels = this.availableModels.filter(model => !this.availableSelected.includes(model));
    this.availableSelected = [];
  }

  moveToAvailable(): void {
    this.availableModels.push(...this.selectedSelected);
    this.selectedModels = this.selectedModels.filter(model => !this.selectedSelected.includes(model));
    this.selectedSelected = [];
  }

  onOkClick(): void {
    this.dialogRef.close(this.selectedModels);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
