import { Component, Input, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'kt-gradient-bar',
  templateUrl: './gradient-bar.component.html',
  styleUrls: ['./gradient-bar.component.scss']
})
export class GradientBarComponent implements OnChanges, AfterViewInit {
  @Input() cutoff: number = 0; // Valor inicial do cursor
  @Input() max: number = 0;
  @Input() score: number = 0;
  @Input() textScore: string = ''; // Texto vindo do input
  @Input() phenotype: string = ''; 
  
  min = 0;
  scoreColor: string = 'gray'; // Variável para armazenar a cor do score

  colorMin1: string = 'green';
  colorMin2: string = 'lightgreen';
  colorCutoff: string = 'yellow';
  colorMax1: string = "orange";
  colorMax2: string = "brown";


  constructor(private cdr: ChangeDetectorRef) {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.score) {
      // Atualizar a cor do score sempre que a pontuação mudar
      this.scoreColor = this.getColorAtScore();
      this.cdr.detectChanges(); // Forçar verificação de mudanças
    }
  }

  ngAfterViewInit(): void {
    this.drawGradient();
    this.scoreColor = this.getColorAtScore();
    this.cdr.detectChanges(); // Forçar verificação de mudanças
  }

drawGradient(): void {
  const canvas = document.getElementById('gradientCanvas') as HTMLCanvasElement;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
    let cutoffPercent = ((this.cutoff - this.min) / (this.max - this.min)) * 100 / 100;

    // Ensure cutoffPercent is within the valid range
    cutoffPercent = Math.max(0.05, Math.min(0.95, cutoffPercent));
    
    gradient.addColorStop(0, this.colorMin1);
    gradient.addColorStop(cutoffPercent - 0.05, this.colorMin2);
    gradient.addColorStop(cutoffPercent, this.colorCutoff);
    gradient.addColorStop(cutoffPercent + 0.05, this.colorCutoff);
    gradient.addColorStop(0.75, this.colorMax1);
    gradient.addColorStop(1, this.colorMax2);

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }
}


  drawGradient2(): void {
    const canvas = document.getElementById('gradientCanvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      const cutoffPercent = ((this.cutoff - this.min) / (this.max - this.min)) * 100 / 100;
  /*    
      gradient.addColorStop(0, 'green');
      gradient.addColorStop(cutoffPercent - 0.05, 'lightgreen');
      gradient.addColorStop(cutoffPercent, 'yellow');
      gradient.addColorStop(cutoffPercent + 0.05, 'yellow');
      gradient.addColorStop(0.75, 'orange');
      gradient.addColorStop(1, 'brown');

*/

      gradient.addColorStop(0, this.colorMin1);
      gradient.addColorStop(cutoffPercent - 0.05, this.colorMin2);
      gradient.addColorStop(cutoffPercent, this.colorCutoff);
      gradient.addColorStop(cutoffPercent + 0.05, this.colorCutoff);
      gradient.addColorStop(0.75, this.colorMax1);
      gradient.addColorStop(1, this.colorMax1);

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
  }

  getColorAtScore(): string {
    const canvas = document.getElementById('gradientCanvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const percent = ((this.score - this.min) / (this.max - this.min)) * canvas.width;
      const clampedPercent = Math.min(Math.max(percent, 0), canvas.width - 1);
      const imageData = ctx.getImageData(clampedPercent, 0, 1, 1).data;
      return `rgb(${imageData[0]}, ${imageData[1]}, ${imageData[2]})`;
    }
    return 'gray'; // Cor padrão se não encontrada
  }

  getGradientStyle(): string {
    const cutoffPercent = ((this.cutoff - this.min) / (this.max - this.min)) * 100;
    /*const gradientStops = [
      `green 0%`,
      `lightgreen ${cutoffPercent - 5}%`,
      `yellow ${cutoffPercent}%`,
      `yellow ${cutoffPercent + 5}%`,
      `orange 75%`,
      `brown 100%`
    ];*/

    const gradientStops = [
      `${this.colorMin1} 0%`,
      `${this.colorMin2} ${cutoffPercent - 5}%`,
      `${this.colorCutoff} ${cutoffPercent}%`,
      `${this.colorCutoff} ${cutoffPercent + 5}%`,
      `${this.colorMax1} 75%`,
      `${this.colorMax2} 100%`
    ];
    return `linear-gradient(to right, ${gradientStops.join(', ')})`;
  }

  getCursorPosition(value: number): string {
    const percent = ((value - this.min) / (this.max - this.min)) * 100;
    const yellowStart = percent - 5;
    const yellowEnd = percent + 5;

    // Calcular o fator de ajuste dinamicamente
    const middle = this.max / 2;
    let adjustmentFactor = 20;
    if (value < middle) {
      if (value < middle / 2) adjustmentFactor = 10;
      else adjustmentFactor = 15;
    }
    // Ajustar a posição do cursor para garantir que ele fique dentro da zona amarela
    const adjustedPercent = yellowStart + ((percent - yellowStart) / (yellowEnd - yellowStart)) * adjustmentFactor;

    // Garantir que a posição ajustada esteja dentro dos limites da barra de gradiente
    const clampedPercent = Math.min(Math.max(adjustedPercent, 0), 100);

    return `calc(${clampedPercent}% - 6px)`;
  }
}
