  <dx-chart
    class="custom-dashed-line"
    palette="red"
    [dataSource]="datasource"
    [argumentAxis]="chartOptions.argumentAxis"
    [valueAxis]="chartOptions.valueAxis"
    title="{{title}}">
  
    <dxi-series valueField="1spe" color="red" dashStyle="dash" name="Random"></dxi-series>
    <dxi-series valueField="sen" color="blue" name="Model"></dxi-series> 
    <dxo-common-series-settings
      #seriesSettings
      argumentField="line"
      type="line"
    >
    
    <dxo-point [visible]="false"></dxo-point>  
    </dxo-common-series-settings>
    <dxo-margin [bottom]="20"></dxo-margin>
    <dxo-common-axis-settings>
      <dxo-grid [visible]="true"></dxo-grid>
    </dxo-common-axis-settings>
    <dxo-legend verticalAlignment="top" horizontalAlignment="right">
    </dxo-legend>
    <dxo-argument-axis [allowDecimals]="false" [axisDivisionFactor]="60">
      <dxo-label>
        <dxo-format type="decimal"></dxo-format>
      </dxo-label>
    </dxo-argument-axis>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-tooltip [enabled]="true"></dxo-tooltip>
  </dx-chart>

