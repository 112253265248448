import {
  Input,
  Output,
  EventEmitter,
  Component,
  OnInit,
} from '@angular/core';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';

import { DxChartModule } from 'devextreme-angular';

import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'kt-chart-analyser',
  templateUrl: './chart-analyser.component.html',
  styleUrls: ['./chart-analyser.component.scss'],

})
export class ChartAnalyserComponent implements OnInit {
  @Input() idChart: string = 'chart1';
  @Input() dataSource: any = [];
  @Input() palette: string='vintage';
  @Input() title: string='Analyser';

  @Input() fpColor: string='#FF8C00';
  @Input() fnColor: string='#CD5C5C';
  @Input() tnColor: string='#00FA9A';
  @Input() tpColor: string='#3CB371';
  
  @Input() totalColor: string='#008fd8';
  @Input() totalType: string="spline";

  @Input() axisTitle: string="Sample Count";


  @Input() verticalLegend: string="bottom";
  @Input() horizontalLegend: string="center";
  
  constructor() {
    locale('en');
  }

  ngOnInit() {

  }

  customizeTooltip({ valueText, point, seriesName }) {
    const items = valueText.split('\n');
    const color = point.getColor();

    items.forEach((item, index) => {
      if (item.indexOf(seriesName) === 0) {
        const element = document.createElement('span');

        element.textContent = item;
        element.style.color = color;
        element.className = 'active';

        items[index] = element.outerHTML;
      }
    });
    return { text: items.join('\n') };
  }
}
