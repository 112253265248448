<canvas id="gradientCanvas" width="800" height="1" style="display: none;"></canvas>
<div class="gradient-bar-container">
  <div class="gradient-bar" [ngStyle]="{'background': getGradientStyle()}">
    <span class="label-min">{{ min }}</span>
    <span class="label-max">{{ max }}</span>
    <div class="cursor-container" [style.left]="getCursorPosition(cutoff)">
      <div class="cursor-label">Cutoff({{ cutoff }})</div>
      <div class="cursor"></div>
    </div>
    <div class="cursor-container score-cursor" [style.left]="getCursorPosition(score)">
      <div class="cursor"></div>
      <div class="cursor-label-score">Result({{ score }})</div>
      <div class="score-info">
        <div class="color-box" [ngStyle]="{'background-color': scoreColor}" style="border-radius:14px">
          <span>{{ textScore }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="data-box">
  <h3 class="data-title">Result Info</h3>
  <div class="data-item">
    <span class="label">Phenotype:</span>
    <span class="value_pheno">{{phenotype}}</span>
  </div>
  <div class="data-item">
    <span class="label">Cutoff:</span>
    <span class="value">{{cutoff}}</span>
  </div>
  <div class="data-item">
    <span class="label">Result:</span>
    <span class="value">{{score}}</span>
  </div>
  <div class="data-item">
    <span class="label">Prediction:</span>
    <span class="value"><b>{{textScore}}</b></span>
  </div>
  <div class="data-item">
    <span class="label">Max:</span>
    <span class="value">{{max}}</span>
  </div>
</div>

