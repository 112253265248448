import {
  Input,
  Output,
  EventEmitter,
  Component,
  ViewChild,
  Renderer2,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import deMessages from 'devextreme/localization/messages/de.json';
import ptMessages from 'devextreme/localization/messages/pt.json';
import notify from 'devextreme/ui/notify';
import { FaceSheetComponent } from 'src/app/pages/workflow/expense/facesheet/face-sheet.component';
import { DxDataGridComponent, DxFilterBuilderComponent } from 'devextreme-angular';

import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { isNotUndefined } from '../../../core/util/functions';
import { environment } from 'src/environments/environment';
import MD5 from 'crypto-js/md5';
// import { FrmComponent } from "../frm/frm.component";
import { DataShareService } from '../../../core/services/datashare.service';
import { validatorNIF } from '../../../core/util/nifValidator';
import { validatorIBAN } from '../../../core/util/IBANValidator';
// import Validator from "devextreme/ui/validator";
// import ExcelJS from "exceljs";
// import saveAs from "file-saver";
// import { forEach } from "lodash";
import { GenericService } from '../../../core/services/generic.service';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';


declare const $: any;
@Component({
  selector: 'kt-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers:[MatDialog]
})
export class GridComponent implements OnInit, AfterViewInit, OnChanges {
  [x: string]: any;
  events: Array<string> = [];
  @ViewChild('dataGridContainer', { static: false }) dataGridContainer: ElementRef;
  @ViewChild('targetDataGrid', { static: false })
  dataGrid: DxDataGridComponent;

  
  @Input() grid: Array<any> = [];
  
  @Input() allowDetail: boolean=false;
  @Input() linkDetail: any= '';

  @Input() dataSource: any = [];
  @Input() folder: String = '';
  @Input() entityType;

  @Input() sourceType;
  @Input() keyCloak;
  @Input() customScreen;
  @Input() gridId = 'gridContainer';

  @Input() id = 'id';

  @Input() hasNavigation: boolean = false;
  @Input() print: boolean = false;
  @Input() selectedRowKeys: any[] = [];

  @Input() gridModel: any;
  
  @Input() modelData: any;
  @Input() origin: any = null;
  @Input() sortArray: any[] = [];
  @Input() isClicable: boolean = true;
  @Input() allowUpdating: boolean = true;
  @Input() allowDeleting: boolean = true;
  @Input() allowAdding: boolean = true;
  @Output() gridEmit: EventEmitter<any> = new EventEmitter();
  @Input() dataChanged: EventEmitter<any> = new EventEmitter();

  @Output() recordManipulate: EventEmitter<any> = new EventEmitter();
  
  //--- UPLOAD FILE
  uploadUrl: String = environment.apiUrl + '/upload/';
  customIcon: String = "chart";
  backendURL: String = environment.apiUrl;
  retryButtonVisible = false;
  @ViewChild('uploadedImage') uploadedImageRef: HTMLImageElement;
  @ViewChild('fileUploader') fileUploaderRef: DxFileUploaderComponent;
  //--------------------------
  locale: string;

  selected: Boolean = false;

  focusedRowIndex: any;
  strCond: string = '';
  pageIndex: any;

  brokenRules: any[];

  popupVisible: boolean;

  rowKey: any;

  prevPageCount: number = 0;

  focusChanged: boolean;

  frmInstance: any;

  loadingVisible: boolean = false;

  contentCheked: any = false;

  focusedRowEnabled: boolean = true;

  totalCountColumn: any;

  editors: any = {};

  storeDependentData: any = {};

  checkedDataSource: boolean = false;
  idPeople: number = 0;
  closeEdit: Boolean = false;
  gridLoaded: boolean;
  idSelected: number = 0;
  //==================
  dataSourceDistricts: DataSource;
  dataSourceCities: DataSource;
  md5Districts: string = '';
  md5IdHCompanies: string = '';
  md5Cities: string = '';
  displayItems: any[] = [];
  idDistrict: number = 0;
  idCounty: number = 0;
  upload: any;
  depDataSource: any = {};
  cond: any;
  index: number = -1;
  indexNew: number = -1;
  indexId: number = -1;
  imageSrc: string = '';
  pathIMG: string = '';
  urlAPI: string = environment.apiUrl + '/';
  auxGrid: any[] = [];
  filteredData: any[] = [];
  constructor(
    private datashareService: DataShareService,
    private service: GenericService,
    private router: Router,
    private elem: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    this.viewData = this.viewData.bind(this);
    //this.shouldShowChartIcon = this.shouldShowChartIcon.bind(this);
    // this.locale = this.getLocale();
    loadMessages(deMessages);
    loadMessages(ptMessages);
    //        locale(navigator.language);
    locale('en');
    /*
		setTimeout(() => {
			this.refresh();
			//console.log("refresh");
		}, 5000); */
  }

  ngOnInit() {
    ////console.log("MD5", MD5("teste").toString());
         
  }

  ngAfterViewInit(): void {
    if (this.router.url === '/base/people') {
      this.focusedRowIndex = this.datashareService.rowIndex;
      this.pageIndex = this.datashareService.pageIndex
        ? this.datashareService.pageIndex
        : 0;
      this.sortArray = this.datashareService.sortingOrder;
      this.prevPageCount = this.dataGrid.instance.pageCount();
    }
  }
  async handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    /* const f: any = {};
    for (const key in file) {
      const value = file[key];
      const notFunction = typeof value !== 'function';
      notFunction && (f[key] = value);
    }
    this.file = f;*/

    var pattern = /image-*/;
    // var reader = new FileReader();
    if (!file.type.match(pattern)) {
      Swal.fire({
        title: 'Formato',
        text: 'Formato de arquivo inválido. Apenas imagens',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn font-weight-bold btn-light',
        },
      }).then(function () {
        //	KTUtil.scrollTop();
      });
      return;
    }

    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const d = await this.service.uploadBase64(formData);
    this.imageSrc = d.file.path.split('assets/').join('');
    this.pathIMG = '';
    if (this.imageSrc !== '') this.pathIMG = this.urlAPI + this.imageSrc;
    //console.log('UPLOAD', this.imageSrc);
    this.cdr.detectChanges();
  }
  ngOnChanges() {
    ////console.log("GRID", this.grid);
    /* 
   setTimeout(() => {
    ////console.log("alterou")
     $(".dx-fileuploader-file-info").hide(); 
     }, 1)//*/
    /*
this.data1 = new DataSource({
           store: new CustomStore({
             key: "id",

             load:  await this.service.getDataResult({model:"districts", lookup : {
                                        "dataSource" : {
                                            "model" : "districts",
                                            "fields" : [
                                                "id",
                                                "name"
                                            ],
                                            "conditions" : [
                                                {
                                                    "id_country" : "184"
                                                }
                                            ]
                                        },
                                        "displayExpr" : "name",
                                        "valueExpr" : "id"
                                    } }),
           }),
        
}) ;
*/
    /**
 await this.service.getDataResult({model:"districts", lookup : {
                                        "dataSource" : {
                                            "model" : "districts",
                                            "fields" : [
                                                "id",
                                                "name"
                                            ],
                                            "conditions" : [
                                                {
                                                    "id_country" : "184"
                                                }
                                            ]
                                        },
                                        "displayExpr" : "name",
                                        "valueExpr" : "id"
                                    } }),
           
          }),
 */

    // this.gridLoaded = this.dataSource ? false : true;
    if(this.gridModel === "omisgen_models") {
          this.customIcon="chart";
    }
    else if(this.gridModel === "omisgen_predictions") {
          this.customIcon="detailslayout";
    }
    this.grid && this.modifyGrid();
    // this.dataSource && this.dataSourceModify();
  }

  ngAfterViewChecked() {
    if (
      !!this.focusChanged &&
      this.dataGrid.instance.getRowIndexByKey(this.datashareService.rowKey) > -1
    ) {
      this.focusedRowIndex = this.dataGrid.instance.getRowIndexByKey(
        this.datashareService.rowKey
      );
      this.focusChanged = false;
    }
    if (!!this.sortArray && this.sortArray.length > 0) {
      this.sortDataSource();
    }
    if (this.prevPageCount != this.dataGrid.instance.pageCount()) {
      this.prevPageCount = this.dataGrid.instance.pageCount();
      this.dataGrid.instance.navigateToRow(this.datashareService.rowKey);
      this.focusChanged = true;
    }
  }

  sortDataSource() {
    for (let i = 0; i < this.sortArray.length; i++) {
      // //console.log('chegouuuuu');
      const sortObj: any = this.sortArray[i];
      const tempObj: any = {};
      switch (sortObj.desc) {
        case false:
          tempObj.sortOrder = 'asc';
          tempObj.sortIndex = i + 1;
          break;
        case true:
          tempObj.sortOrder = 'desc';
          tempObj.sortIndex = i + 1;
          break;
        default:
          break;
      }
      if (
        !!this.dataGrid &&
        !!sortObj &&
        !!sortObj.selector &&
        !!this.dataGrid.instance.columnOption(sortObj.selector)
      ) {
        this.dataGrid.instance.columnOption(sortObj.selector, tempObj);
        // this.dataGrid.instance.navigateToRow(this.datashareService.rowKey);
        // this.sortArray = [];
        this.sortArray.splice(
          this.sortArray.findIndex(
            (item) => item.selector === sortObj.selector
          ),
          1
        );
      }
    }
  }

  setDisplayExpr(item) {
    const keys = Object.keys(item);
    return item[keys[1]];
  }

  checkUndefined(val) {
    return isNotUndefined(val);
  }

  baseEmit(event) {
    //console.log('pois', event);
  }

  show(event) {
    if (!this.isClicable) return;
    //console.log('clicou', this.isClicable);
    /*//console.log(
      'show-----',
      event,
      this.dataGrid.instance.getDataSource().sort(),
    );*/
    this.datashareService.updateSortingOrder(
      this.dataGrid.instance.getDataSource().sort()
    );
    const elements = this.elem.nativeElement.querySelectorAll(
      '.dx-datagrid-edit-form'
    );
    // if(elements.length === 0){
    this.datashareService.updateRowKey(event.key);
    this.datashareService.updateRowIndex(event.rowIndex);
    /* this.focusedRowEnabled = true;
	}else{
		this.focusedRowEnabled = false;
	} */
    this.datashareService.updatePageIndex(this.dataGrid.instance.pageIndex());
    //  //console.log('TIPOOOO', this.entityType);

    if (this.entityType === 'popup') {
      this.dataGrid.instance.cancelEditData();
      this.recordManipulate.emit({
        action: 'popupUpdate',
        id: event.data[this.id],
        key: event.key,
        sourceType: this.sourceType,
        modelData: this.modelData,
        data: event.data,
      });
    }

    if (this.entityType === 'custom') {
      this.dataGrid.instance.cancelEditData();
      this.recordManipulate.emit({
        action: 'popupOpen',
        id: event.data[this.id],
        key: event.key,
        sourceType: this.sourceType,
        modelData: this.modelData,
        data: event.data,
      });
    }

    if (this.selected) {
      return;
    }
    this.selected = true;
    // //console.log(event.element.nativeElement.id);
    // //console.log("meu id=", id);

    this.idSelected = event.data[this.id];
    this.gridEmit.emit({
      action: 'selected',
      id: event.data[this.id],
      model: this.gridModel,
      key: this.gridId,
      sourceType: this.sourceType,
      data: event.data,
    });
    setTimeout(() => {
      this.selected = false;
    }, 300);
  }
  onRowUpdating(event) {
    if (typeof event === 'undefined') return;

    let id = -1;
    let key = 0;
    let data: any = {};
    if (event) {
      id = event.element.id;
      key = event.key;
      const aux = event.newData;
      if(aux.max_false_positive)
         event.newData.status ="IN PROGRESS";
      data = event.newData;
      
    }
    const obj = {
      action: 'update',
      id,
      key,
      data,
      sourceType: this.sourceType,
      model: this.gridModel,
    };

    let promise = new Promise(async (resolve, reject) => {
      try {
        //console.log('obj', obj);
        const result = await this.service.updateData(obj, event.key);
        //console.log('result>', result);
        notify('Modification successfully completed', 'success', 3000);
        resolve(false);
      } catch (error) {
        /* notify(
          `Erro ao tentar realizar atualização: ${error.message}`,
          'error',
          5000
        );*/
        resolve(true);
      }
    });
    event.cancel = promise;
  }
  empty(event) {
    //  //console.log('Grid empty', event);
    if (typeof event === 'undefined') return;
    // //console.log('event', event);
    let id = -1;
    let key = 0;
    let data: any = [];
    if (event) {
      id = event.element.id;
      key = event.key;
      data = event.data;
    }
    this.gridEmit.emit({
      action: 'new',
      id,
      key,
      data,
      sourceType: this.sourceType,
      model: this.gridModel,
    });
  }

  UPPER(str: string) {
    return str.toUpperCase();
  }

  logEvent(eventName, event) {
    //console.log('----LogEvent----', eventName);
    // 	this.events.unshift(eventName);
    // //console.log(JSON.stringify(eventName));
    // //console.log("key", event.key);
    // //console.log("data", event.data);
    // //console.log("datasss", event);
    for (let i = 1; i < event.element.column.length; i++) {
      this.displayItems.forEach((e) => {
        if (e === event.element.column[i].dataField) {
          event.element.column[i].visible = false;
        }
      });
    }
    if (this.imageSrc !== '') {
      event.data.image = this.imageSrc;
      this.pathIMG = this.urlAPI + this.imageSrc;
    } else this.pathIMG = '';
    if (eventName === 'insert') {
      this.gridEmit.emit({
        action: eventName,
        key: event.key,
        id: event.element.id,
        data: event.data,
        sourceType: this.sourceType,
        model: this.gridModel,
      });
    }

    // return;
  }
  displayVisible(event) {
    event.changes = [];
    if (this.retryButtonVisible) {
      this.retryButtonVisible = false;
    }
    for (let i = 1; i < event.element.column.length; i++) {
      this.displayItems.forEach((e) => {
        if (e === event.element.column[i].dataField) {
          event.element.column[i].visible = false;
        }
      });
    }
    //this.dataGrid.instance.refresh();
    this.cdr.detectChanges();
  }
  onRowInserting(event) {
    if (typeof event === 'undefined') return;

    let id = -1;
    let key = 0;
    let data: any = [];
    if (event) {
      id = event.element.id;
      key = event.key;
      data = event.data;
    }
    const obj = {
      action: 'insert',
      id,
      key,
      data,
      sourceType: this.sourceType,
      model: this.gridModel,
    };
    let promise = new Promise(async (resolve, reject) => {
      try {
        ////console.log('obj', obj);
        const result: any = await this.service.addData(obj, event.key);
        //console.log(obj)
        ////console.log('result', result);
        if (result.status === 200) {
          event.data.id = result.result.id;
          if(obj.model==='omisgen_models' || obj.model==='omisgen_datasets') {
            event.data.status ="IN PROGRESS";
          }
        }
        //console.log('eventtt', event);
        notify('Registration successfully completed', 'success', 3000);
        this.ngAfterViewInit();
        resolve(false);
      } catch (error) {
        //   notify('Erro ao tentar realizar o cadastro', 'error', 5000);
        resolve(true);
      }
    });
    event.cancel = promise;
  }
  onRowInserted(event) {
    setTimeout(() => {
      event = undefined;
    }, 500);
  }

  async onEditingStart(event) {
    if (this.keyCloak || this.customScreen) {
      this.router.navigateByUrl(`config/${this.gridModel}/${event.key}`, {
        replaceUrl: true,
        skipLocationChange: true,
      });
      return;
    }
    this.depDataSource['id_h_company'] = null;
    this.idPeople = 0;
    this.indexNew = -1;
    //event.saveButtonOptions.type = 'success';

    if (Object.prototype.hasOwnProperty.call(event.data, 'image')) {
      this.imageSrc = event.data.image;
      this.pathIMG = '';
      if (this.imageSrc !== '') this.pathIMG = this.urlAPI + this.imageSrc;
    }
    this.cond = null;
    this.index = -1;
    this.indexId = -1;
    this.depDataSource = {};
    this.idSelected = event.data[this.id];
    this.displayItems = [];
     
    for (let i = 1; i < event.element.column.length; i++) {
     
      if (!event.element.column[i].visible) {
        // //console.log("DISPLAY", event.element.column[i]);
        if (!this.displayItems.includes(event.element.column[i].dataField)) {
          this.displayItems.push(event.element.column[i].dataField);
        }

        if (
          Object.prototype.hasOwnProperty.call(
            event.element.column[i],
            'alwaysVisible'
          )
        ) {
          
          event.element.column[i].visible = false;
          ////console.log('VISIBLE PEOPLE', event.element.column[i].alwaysVisible);
        } else event.element.column[i].visible = true;
        
      } 
    }
    this.datashareService.updateRowKey(event.key);
    this.datashareService.updateRowIndex(event.rowIndex);
    this.focusedRowIndex = this.dataGrid.instance.getRowIndexByKey(event.key);

    this.index = this.dataGrid.instance.getRowIndexByKey(event.data.id);

    this.indexId = event.data.id;

    // //console.log('INDEX', this.index);
    //await this.checkDependencies(this.grid, 'id_h_company');
    if (this.entityType === 'complex') {
            this.dataGrid.instance.cancelEditData();
      this.recordManipulate.emit({
        action: 'update',
        id: event.data[this.id],
      });

/*
      this.dataGrid.instance.cancelEditData();
      this.recordManipulate.emit({
        action: 'update',
        id: event.data[this.id],
        key: event.key,
        sourceType: this.sourceType,
      }); */
    } else if (this.entityType === 'popup') {
      // this.dataGrid.instance.cancelEditData();

      this.recordManipulate.emit({
        action: 'popupUpdate',
        id: event.data[this.id],
        key: event.key,
        sourceType: this.sourceType,
        modelData: this.modelData,
        data: event.data,
      });
    }
    this.cdr.detectChanges();
  }

  async checkDependencies(grid: any[], dataField: string, id: number = 0) {
    const field: any = grid.filter((e) => e.dataField === dataField);
    if (field === undefined || field.length === 0) return;
    //if (Object.prototype.hasOwnProperty.call(field[0], 'dependencies')) {
    if (field[0].dependencies) {
      // alert('aquii ' + JSON.stringify(grid[i]));
      this.cond = JSON.stringify(field[0].lookup.dataSource.listData);
      if (id > 0) {
        let cond = this.cond;
        cond = JSON.parse(cond.split('"0"').join(id));
        //   //console.log('COND', cond);
        const y = await this.service.getData({
          listData: cond,
        });

        if (y.code === 200) {
          this.depDataSource['id_h_company'] = y.result;
          return;
          // //console.log('oia so x ', this.depDataSource, this.index);
        }
      }
    }

    /*for (let i = 0; i < grid.length; i++) {
      if (grid[i].dependencies) {
        // alert('aquii ' + JSON.stringify(grid[i]));
        this.cond = JSON.stringify(grid[i].lookup.dataSource.listData);
        if (id > 0) {
          let cond = this.cond;
          cond = JSON.parse(cond.split('"0"').join(id));
          //   //console.log('COND', cond);
          const y = await this.service.getData({
            listData: cond,
          });

          if (y.code === 200) {
            this.depDataSource['id_h_company'] = y.result;
            return;
            // //console.log('oia so x ', this.depDataSource, this.index);
          }
        }
      }
    }*/
  }
  async onRowRemoving(event) {
    //  //console.log('sourcetype', this.sourceType);

    const obj = {
      action: 'delete',
      key: event.key,
      id: event.element.id,
      data: event.data,
      sourceType: this.sourceType,
      model: this.gridModel,
    };

    if (this.entityType === 'popup') {
      const activeResult = this.activeRuleCheck(event.data);
      if (activeResult) {
        //    //console.log('activeResult', activeResult);
        event.cancel = true;
      }

      this.recordManipulate.emit({
        action: 'popupdelete',
        id: event.data[this.id],
        key: event.key,
        sourceType: this.sourceType,
        modelData: this.modelData,
        data: event.data,
      });
    }

    let promise = new Promise(async (resolve, reject) => {
      try {
        let result;
        if (!this.keyCloak && !this.customScreen)
          result = await this.service.deleteData(obj, event.key);
        else
          result = await this.service.deleteKeycloak(this.gridModel, event.key);
        //  //console.log('result', result);
        notify('Deletion successfully completed', 'success', 2000);
        resolve(false);
      } catch (error) {
        //  notify('Erro ao tentar excluir', 'error', 1000);
        resolve(true);
      }
    });
    event.cancel = promise;
  }

  activeRuleCheck(data) {
    if (this.modelData.element === 'rules') {
      return data.active;
    }
    return false;
  }

  async onRowValidating(event) {
    try {
      if (this.imageSrc !== '') {
        event.newData.image = this.imageSrc;

        if (this.imageSrc !== '') this.pathIMG = this.urlAPI + this.imageSrc;
      } else this.imageSrc = '';
      if (event.brokenRules.length > 0) {
        event.isValid = false;
      }
      //console.log('----Click validation----', event);

      //    //console.log('THIS>GRID', this.grid);

      //	if(!event.isValid) return  false;
      for (const gridField of this.grid) {
        if (gridField.dependentColumn && !event.newData[gridField.dataField]) {
          event.newData[gridField.dataField] =
            this.storeDependentData[gridField.dataField];
        }
        if (!gridField.visible) {
          event.newData[gridField.dataField] = gridField.defaultValue
            ? gridField.defaultValue
            : event.oldData && event.oldData[gridField.dataField]
            ? event.oldData[gridField.dataField]
            : undefined;
        }
      }
      this.brokenRules = [];
      if (event.newData && Object.keys(event.newData).length > 0) {
        if (
          event.newData.hasOwnProperty('iban') ||
          event.newData.hasOwnProperty('hours')
        )
          event.isValid = this.customValidation(event.newData, event);
      }

      // //console.log('ESVENT', event.brokenRules);
      if (
        !event.isValid &&
        Object.keys(event.newData).length > 0 &&
        event.newData.hasOwnProperty('iban')
      ) {
        const errObj: any = {};
        errObj.type = 'custom';
        errObj.message = 'Informe um IBAN Válido para o banco selecionado';
        errObj.isValid = 'false';
        errObj.field = 'iban';

        this.brokenRules.push(errObj);
        //	this.brokenRules = [...event.brokenRules];
        this.popupVisible = true;
      }

      this.storeDependentData = {};
      ////console.log('VALIDACAO', event.isValid, event);
      if (!event.isValid) return;
    } catch (error) {
      //console.log(error);
    }
  }

  customValidation(data, event) {
    // this.customValidationCheck.emit(data);

    if (!!data.iban || !!data.id_bank) {
      const id_bank = data.id_bank ? data.id_bank : event.oldData.id_bank;
      const iban = data.iban ? data.iban : event.oldData.iban;
      const validIBAN = validatorIBAN(iban, id_bank);

      if (!validIBAN) {
        const errObj: any = {};
        errObj.type = 'custom';
        errObj.message = 'Informe um IBAN Válido para o banco selecionado';
        errObj.isValid = 'false';
        errObj.field = 'iban';
        //		this.brokenRules.push(errObj);
        return false;
      }
    }
    if (!!data.hours && !!data.start_date && !!data.end_date) {
      const hour = data.hours;
      const d1 = data.start_date;
      const d2 = data.end_date;
      if (hour && hour !== 0 && d1.getTime() !== d2.getTime()) {
        const errObj: any = {};
        errObj.type = 'custom';
        errObj.message =
          'As datas inicial e final devem ser iguais para quantidade de horas maior do que zero';
        errObj.isValid = 'false';
        errObj.field = 'hours';
        this.brokenRules.push(errObj);
        Swal.fire({
          title: 'Ação inválida',
          text: errObj.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn font-weight-bold btn-light',
          },
        }).then(function () {
          //	KTUtil.scrollTop();
        });
        event.brokenRules.push(errObj);
        return false;
      }
    }
    if (!!data.nif && !validatorNIF(data.nif)) {
      //console.log('Check-----', validatorNIF(data.nif), event);
      const errObj: any = {};
      errObj.type = 'custom';
      errObj.message = 'Custom Message';
      errObj.isValid = 'false';
      errObj.field = 'nif';
      this.brokenRules.push(errObj);
      return false;
    }
    if (event.brokenRules.length > 0) {
      return false;
    }
    return true;
  }

  refresh() {
    this.dataGrid.instance.refresh();
  }

  clearEvents() {
    this.events = [];
  }

  customizeItem(item: any) {
    item.visible = item.column.visible;
  }

  viewData(e) {
    
        if(this.gridModel === "omisgen_models") {
          if(e?.row?.data?.status !== "GENERATED") {
            Swal.fire({
              title: 'Model Controller',
              text: 'Action allowed only to status GENERATED',
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn font-weight-bold btn-light',
              },
            }).then(function () {
              //	KTUtil.scrollTop();
            });
            return;
          }
        }
        this.router.navigateByUrl(`${this.linkDetail}${e.row.data.id}`);
  }
getFormItemsWithoutReadOnly(e) {
  
     return e.element.column.filter(e=> e.readOnly !== true);
  
  }

  async onInitNewRow(event) {
    this.depDataSource['id_h_company'] = null;
    this.idPeople = 0;
    event.data.id_h_company = '';
    
    event.component.option('editing.form.items', this.getFormItemsWithoutReadOnly(event));
    
    if (this.dataGrid.instance.hasEditData()) {
      event.data.id_h_company = '';
      this.closeEdit = true;
      this.dataGrid.instance.cancelEditData(); // cancel the edit operation
      return;
      //  this.dataGrid.instance.addRow();
    }

    //console.log('oninitnewrow');

    this.indexNew = this.dataGrid.instance.totalCount();
    this.index = this.indexNew;

    //*
    for (let i = 1; i < event.element.column.length; i++) {
      if (!event.element.column[i].visible) {
        if (!this.displayItems.includes(event.element.column[i].dataField)) {
          this.displayItems.push(event.element.column[i].dataField);
        }

        if (
          Object.prototype.hasOwnProperty.call(
            event.element.column[i],
            'alwaysVisible'
          )
        ) {
        } else {
          event.element.column[i].visible = true;
        }
      }
    } //*/

    if (this.entityType === 'complex') {
      this.dataGrid.instance.cancelEditData();
      this.recordManipulate.emit({
        action: 'new',
      });
    } else if (this.entityType === 'popup') {
      this.dataGrid.instance.cancelEditData();
      this.recordManipulate.emit({
        action: 'popupNew',
        sourceType: this.sourceType,
        modelData: this.modelData,
      });
    }

    ////console.log(this.grid);
    //onsole.log('colum', event);
    await this.checkDependencies(this.grid, 'id_h_company', 0);

    this.index = this.indexNew;
    ////console.log(this.grid);
    /*
    //console.log(
      'NEW ROW',
      event.element.column,
      this.dataGrid.instance.totalCount(),
      this.depDataSource
    );*/


    const gridInstance = this.dataGrid.instance;
    const columns = gridInstance.getVisibleColumns();

    // Personaliza o formulário de inserção para ocultar campos readonly
    const formItems = columns
      .filter((column: any) => column.allowEditing !== false)  // Apenas campos editáveis
      .map((column: any) => ({ dataField: column.dataField }));

    // Atualiza o layout do formulário de inserção
    if(event.formOptions)
    event.formOptions.items = formItems;
  }

  customCheck() {
    //console.log('opa entrei aqui');
  }

  async prepared(evt: any) {
    //if (evt.dataField === 'postal_code')
    //  //console.log('ACABUU', evt.index + ' FIELD: ' + evt.dataField);
    //  this.dataGrid.instance.repaint();
  }

  cascadeQuery(model: string, key: string, value: string) {
    if (!value || value === 'null') value = '1';
    return JSON.parse(`{
                                            "model" : "${model}",
                                            "fields" : [
                                                "id",
                                                "name"
                                            ],
                                            "conditions" : [
                                                {
                                                    "${key}" : "${value}"
                                                }
                                            ],
                                        "displayExpr" : "name",
                                        "valueExpr" : "id"
                                  }`);
  }

  async onEditorPreparing(e) {
    const t = this;


    if (this.closeEdit) {
      e.cancel = true;
      this.dataGrid.instance.cancelEditData();
      this.closeEdit = false;
      Object.keys(e.row.data).forEach((key) => {
        e.row.data[key] = null; // or e.row.data[key] = '';
      });
      this.cdr.detectChanges();
      //   return;
    }
    if (e.row) {
      const c = this.grid.filter((ev) => ev.dataField === e.dataField);

      if (c[0].hasOwnProperty('maxLength')) {
        e.editorOptions.maxLength = `${c[0].maxLength}`;
        // //console.log('entrou', e.dataField);
      }
      if (c[0].hasOwnProperty('readOnly')) {
        e.editorOptions.readOnly = `${c[0].readOnly}`;
        // //console.log('entrou', e.dataField);
      }
    }
    if (e.dataField === 'code' && e.parentType === 'dataRow') {
      const c = this.grid.filter((ev) => ev.dataField === e.dataField);

      if (c[0].hasOwnProperty('maxLength')) {
        e.editorOptions.maxLength = `${c[0].maxLength}`;
      }
    }

    if (
      e.dataField === 'id_people' &&
      e.parentType === 'dataRow' &&
      this.origin === 'values'
    ) {
      if (this.indexNew === -1) e.editorOptions.readOnly = true;
      //  alert(JSON.stringify(t.cond));
      const defaultValueChangeHandler = e.editorOptions.onValueChanged;

      e.editorOptions.onValueChanged = async (ev: any) => {
        // //console.log('prevent', ev.previusValue, ev.value);
        if (ev.previusValue !== ev.value) {
          //  //console.log('eee', ev.value, t.cond);
          this.idPeople = ev.value;
          if (ev.value && t.cond) {
            let cond = t.cond;
            cond = JSON.parse(cond.split('"0"').join(ev.value));
            //console.log('COND', cond);
            const y = await t.service.getData({
              listData: cond,
            });
            if (y.code === 200) {
              this.index = this.indexNew || e.row.rowIndex;
              t.depDataSource['id_h_company'] = y.result;
              defaultValueChangeHandler(ev);
              if (this.indexNew > -1) {
                defaultValueChangeHandler(ev);
                this.dataGrid.instance.repaintRows([this.indexNew]);
                this.dataGrid.instance.repaint();
              }
            }
          }
        }
      };
      if (e.value && t.cond && e.parentType === 'dataRow') {
        this.idPeople = e.value;
        let cond = t.cond;
        cond = JSON.parse(cond.split('"0"').join(e.value));
        const y = await t.service.getData({
          listData: cond,
        });
        if (y.code === 200) {
          t.depDataSource['id_h_company'] = y.result;
          // //console.log('chegou aqui');
          if (this.index > -1) {
            this.dataGrid.instance.repaintRows([this.index]);

            //this.dataGrid.instance.repaintRows([this.index]);
            this.dataGrid.instance.repaint();
            //t.cdr.detectChanges();
            this.index = -1;
          }
        }
      }
    }
    if (
      e.dataField === 'id_h_company' &&
      e.parentType === 'dataRow' &&
      this.origin === 'values'
    ) {
      if (this.idPeople !== 0 && t.depDataSource[e.dataField])
        await this.checkDependencies(this.grid, 'id_h_company', this.idPeople);
      //  //console.log('chegouu', t.depDataSource[`${e.dataField}`]);
      e.lookup.value = null;
      if (t.depDataSource[e.dataField]) {
        e.lookup.value = null;
        e.lookup.dataSource = t.depDataSource[e.dataField];
        e.lookup.items = t.depDataSource[e.dataField];
        if (e.lookup.dataSource.length == 1) {
          //     e.lookup.value = e.lookup.dataSource[0].id;
          const rowIndex = (e.row && e.row.rowIndex) || this.index;
          const component = e.component;
          component.cellValue(
            rowIndex,
            'id_h_company',
            e.lookup.dataSource[0].id
          );
          //t.depDataSource[e.dataField] = null;
          // this.cdr.detectChanges();
        }
      }
      //t.dataGrid.instance.repaint();
      if (this.index > -1 && t.depDataSource['id_h_company']) {
        e.lookup.value = null;
        e.lookup.dataSource = t.depDataSource[e.dataField];
        e.lookup.items = t.depDataSource[e.dataField];
        if (e.lookup.dataSource) {
          //     e.lookup.value = e.lookup.dataSource[0].id;
          const rowIndex = e.row && e.row.rowIndex;
          const component = e.component;
          component.cellValue(
            rowIndex,
            'id_h_company',
            e.lookup.dataSource[0].id
          );
          //if (t.idPeople > 0) {
          //   t.depDataSource[e.dataField] = null;
          //   t.idPeople = 0;
          // this.dataGrid.instance.repaintRows([this.index]);
          // }
        }
        //  //console.log('aaqui entrou ');
        // this.dataGrid.instance.repaintRows([this.index]);
        //this.dataGrid.instance.repaintRows([this.index]);
        this.dataGrid.instance.repaint();
        //t.cdr.detectChanges();
        this.index = -1;
      }
    }
    if (t.depDataSource[e.dataField]) {
      // //console.log('chegou yeah 1 2 3', t.depDataSource[e.dataField]);
      //e.lookup.dataSource = t.depDataSource[e.dataField];

      if (this.index > -1) {
        e.lookup.value = null;
        e.lookup.dataSource = t.depDataSource[e.dataField];
        e.lookup.items = t.depDataSource[e.dataField];
        if (e.lookup.dataSource.length === 1) {
          //     e.lookup.value = e.lookup.dataSource[0].id;
          const rowIndex = 0; //this.index || (e.row && e.row.rowIndex);
          const component = e.component;
          component.cellValue(
            rowIndex,
            'id_h_company',
            e.lookup.dataSource[0].id
          );
          //if (t.idPeople > 0) {
          //   t.depDataSource[e.dataField] = null;
          //   t.idPeople = 0;
          // this.dataGrid.instance.repaintRows([this.index]);
          // }
        }
        this.dataGrid.instance.repaintRows([this.index]);
        this.index = -1;
        this.dataGrid.instance.repaint();
        //  t.cdr.detectChanges();
      }
    }
    if (e.dataField === 'postal_code' && e.parentType === 'dataRow') {
      //e.editorType = 'dxAutocomplete';
      // //console.log('AQUIIII', e.editorOptions);
      e.editorOptions.valueChangeEvent = 'keyup';
      e.editorOptions.onValueChanged = async function onPostalCodeChanged(f) {
        // //console.log('EEEE', f.value);
        //  //console.log('postal_code', f.value.replace('-', ''));
        const x = await t.service.getDataPerRoute('address', {
          postal_code: f.value.replace('-', ''),
        });
        // //console.log('data', x);
        if (x.length > 0) {
          const rowIndex = e.row && e.row.rowIndex;
          const component = e.component;
          component.cellValue(rowIndex, 'postal_code', f.value);
          component.cellValue(rowIndex, 'parish', x[0].freguesia);
          component.cellValue(rowIndex, 'district', x[0].distrito);
          component.cellValue(rowIndex, 'address', x[0].morada);
          component.cellValue(rowIndex, 'id_county', x[0].id_county);
          component.cellValue(rowIndex, 'county', x[0].concelho);
          component.cellValue(rowIndex, 'id_parish', x[0].id);
          /*e.row.data.postal_code = f.value;
          e.row.data.parish = x[0].freguesia;
          e.row.data.district = x[0].distrito;
          e.row.data.address = x[0].morada;
          e.row.data.city = x[0].concelho;
          e.row.data.id_parish = x[0].id;
          //t.dataGrid.instance.repaintRows([t.index]);
          t.dataGrid.instance.refresh();*/
          //t.cdr.detectChanges();
          t.dataGrid.instance.refresh();
          //console.log('XXXXXX', rowIndex, e.row);
        }

        //this.onPostalCodeChanged;
      };
    }

    //*
    if (e.parentType === 'dataRow') {
      // if(e.dataField=="id_country")

      e.editorOptions.deferRendering = false;
      const gridItem = t.grid.find((x) => x.dataField === e.dataField);
      //  //console.log("ITEMMMM", gridItem);
      // if(Object.keys(e.row.data).length != 0 && Object.keys(this.storeDependentData).length != 0){e.row.data = this.storeDependentData}
      t.storeDependentData = e.row.data;
      if (gridItem.endPoint) {
        e.editorOptions.grouped = !!gridItem.endPoint.fields.group;
        e.editorOptions.dataSource = new DataSource({
          store: new ArrayStore({
            data: gridItem.lookup.dataSource.result,
            key: gridItem.endPoint.fields.id,
          }),
          group: gridItem.endPoint.fields.group,
        });
      }

      if (gridItem.dependentColumn) {
        t.storeDependentData = e.row.data;
        e.editorOptions.onValueChanged = async (args) => {
          for (const element of gridItem.dependentColumn) {
            this.storeDependentData[gridItem.dataField] = args.value;
            const lookupItem = t.grid.find((x) => x.dataField === element);
            let cond = lookupItem.lookup.dataSource;
            //delete cond.listData;
            lookupItem.dependencies.forEach((item) => {
              cond = JSON.stringify(cond);
              cond = JSON.parse(
                cond.split(`:${item}`).join(t.storeDependentData[item])
              );
            });
            let result: any = await t.service.getLookupdata(cond);
            //   //console.log('result>>>', result);
            result = JSON.stringify(result);

            if (result !== undefined)
              result = JSON.parse(result.split('company.name').join('name'));
            lookupItem.lookup.dataSource.result = result;
          }
          t.cdr.detectChanges();
        };
      }
      // }
      if (!!gridItem.dependencies && gridItem.dependencies.length > 0) {
        if (Object.keys(t.storeDependentData).length != 0) {
          let cond = gridItem.lookup.dataSource;
          // delete cond.listData;
          gridItem.dependencies.forEach(async (item) => {
            cond = JSON.stringify(cond);
            cond = JSON.parse(
              cond.split(`:${item}`).join(t.storeDependentData[item])
            );
          });
          //   //console.log("CONDDDD", cond)
          let result = await t.service.getLookupdata(cond);
          //   //console.log('result>>>', result);
          if (result) {
            result = JSON.stringify(result);
            result = JSON.parse(result.split('company.name').join('name'));
          }
          e.lookup.dataSource.result = result;
        } else {
          e.lookup.dataSource.result = [];
        }
      }
      if (e.editorName === 'dxSelectBox') {
        e.editorOptions.onContentReady = function (args) {
          $('.dx-datagrid-nodata').hide();
          if (
            args.component.option('value') == null &&
            e.lookup.dataSource &&
            e.lookup.dataSource.length === 1
          ) {
            const item = args.component.option('items')[0];
            args.component.option('value', item[e.editorOptions.valueExpr]);
          }
        };
      }
    } //*/
    //  }, 1000);
  }
  open(path) {
    // //console.log(path);
    if (path) return environment.apiUrl + '/' + path;
    return '';
  }
  removeImage() {
    this.imageSrc = '';
    this.pathIMG = '';

    // this.form.controls['logo'].setValue('');
    this.cdr.detectChanges();
  }
  onCellPrepared(e) {}
  async changed(event) {
    //  //console.log(event);
    // //console.log('$event', event.value);
    //    //console.log(t.grid, this.grid);
    //await t.checkDependencies(t.grid, event.value);
    ////console.log('AGORA AQUI', this.depDataSource);
  }

  async modifyGrid() {
    for (const iterator of this.grid) {
      if (iterator.endPoint) {
        // this.service.getDataFromEndpoint(iterator["endPoint"]["path"]).then(res=>{
        iterator.lookup = {
          dataSource: {
            result: await this.service.getDataFromEndpoint(
              iterator.endPoint.path
            ),
          },
          valueExpr: iterator.endPoint.fields.id,
          displayExpr: iterator.endPoint.fields.name,
        };
        // })

        // iterator["endPoint"]["dataSource"] = await this.service.getDataFromEndpoint(iterator["endPoint"]["path"]);
      }
    }

    this.cdr.detectChanges();
  }

  async dataSourceModify() {
    for (const row of this.dataSource) {
      await this.lookupinGrid(row);
    }
    this.gridLoaded = true;
    this.cdr.detectChanges();
  }

  async lookupinGrid(eachRowData) {
    //  //console.log('EACHROWDATA', eachRowData);
    for (const key in eachRowData) {
      const gridItem = this.grid.find((x) => x.dataField === key);
      this.storeDependentData = eachRowData;
      if (
        !!gridItem &&
        !!gridItem.dependencies &&
        gridItem.dependencies.length > 0
      ) {
        let cond = gridItem.lookup.dataSource;

        gridItem.dependencies.forEach(async (item) => {
          cond = JSON.stringify(cond);
          cond = JSON.parse(
            cond
              .split(`:${item}`)
              .join(this.service.formatDate(this.storeDependentData[item]))
          );
        });

        if (!gridItem.lookup.dataSource.result) {
          gridItem.lookup.dataSource.result = [];
        }
        const result = await this.service.getLookupdata(cond);
        // this.service.getLookupdata(cond).then(result=>{
        result.forEach((element) => {
          element = JSON.stringify(element);
          element = JSON.parse(element.split('company.name').join('name'));
          const found = gridItem.lookup.dataSource.result.find(
            (x) => x.id === element.id
          );
          if (!found) {
            gridItem.lookup.dataSource.result.push(element);
          }
        });
        // });
      }
    }
    return true;
  }

  onContentReady(e) {
    // //console.log('chegou', e);
    //e.component.cancelEditData();

    //  if (this.gridModel === 'companies') return;
    // //console.log('teste', this.gridModel);
    $('.dx-datagrid-nodata').hide();
    this.checkEditFormOpened();
    if (this.grid && this.grid !== this.auxGrid) this.auxGrid = [];
    // this.totalCountColumn = e.component.getVisibleColumns()[0] ? e.component.getVisibleColumns()[0]['dataField'] : '';
  }

  customizeText(options) {
    return options.valueText;
  }

  checkFormat(dataType) {
    if (dataType === 'date') {
      return { type: 'dd-MM-yyyy' };
    }
  }

  checkEditFormOpened() {
    const elements = this.elem.nativeElement.querySelectorAll(
      '.dx-datagrid-edit-form'
    );

    const editElements =
      this.elem.nativeElement.querySelectorAll('.dx-icon-edit');
    if (
      (this.grid && this.auxGrid !== this.grid && elements.length > 0) ||
      (this.entityType === 'popup' && elements.length > 0)
    ) {
      if (this.auxGrid.length > 0) this.dataGrid.instance.cancelEditData();
      this.auxGrid = this.grid;
      if (this.entityType === 'popup') this.dataGrid.instance.cancelEditData();
    } else if (elements.length > 0) {
      for (var i = 0; i < editElements.length; i++) {
        editElements[i].style.setProperty('display', 'none');
        this.focusedRowEnabled = false;
      }
    } else {
      for (var i = 0; i < editElements.length; i++) {
        editElements[i].style.setProperty('display', '');
        this.focusedRowEnabled = true;
      }
    }
  }

  // UPLOAD IMAGE
  onClick(e: any): void {
    //    //console.log("CLICOU UPLOAD", e);
    // The retry UI/API is not implemented. Use a private API as shown at T611719.
    const fileUploaderInstance = this.fileUploaderRef.instance;
    // //console.log("FILEUPLOAD", this.fileUploaderRef.instance);
    // @ts-ignore
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < fileUploaderInstance._files.length; i++) {
      // @ts-ignore
      delete fileUploaderInstance._files[i].uploadStarted;
    }
    // @ts-ignore
    fileUploaderInstance.upload();
  }

  onValueChanged(e: any): void {
    const t = this;
    const reader: FileReader = new FileReader();
    reader.onload = (function (args) {
      //   //console.log('AEGGRGHS', args);
      return function (ee) {
        const binaryData: any = ee.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //  //console.log("BASE", base64String);

        //showing file converted to base64
        //  if ( e.value !== e.previousValue ) {
        ////console.log('VALUEEE', e.value);
        //     t.uploadUrl = t.updateQueryStringParameter(t.uploadUrl, "base64", base64String);
        t.uploadUrl = t.updateQueryStringParameter(
          t.uploadUrl,
          'name',
          t.idSelected
        );
        t.uploadUrl = t.updateQueryStringParameter(
          t.uploadUrl,
          'type',
          args.type
        );
        t.uploadUrl = t.updateQueryStringParameter(
          t.uploadUrl,
          'folder',
          t.folder
        );
        //  t.uploadUrl = t.updateQueryStringParameter(t.uploadUrl, "data", {base64: base64String, name: t.idSelected, type: args.type, folder: t.folder})
        // //console.log("t.uploadUrl", t.uploadUrl)  ;
        t.cdr.detectChanges();
        t.service.uploadBase64({
          base64: base64String,
          name: t.idSelected,
          type: args.type,
          folder: t.folder,
        });
        //    }
        // t.upload=base64String;
      };
    })(e.value[0]);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(e.value[0]);
    /*    setTimeout(() => {
                     //console.log("UPLOAD",this.upload)
                       t.service.uploadBase64({base64: this.upload});// name: args.name, type: args.type})
                   }, 500)*/

    /*
    reader.onload = (args) => {
      if (typeof args.target.result === 'string') {
        this.uploadedImageRef.src = args.target.result;
      }
    };
     reader.readAsDataURL(e.value[0]); // convert to base64 string
    */
  }

  updateQueryStringParameter(uri, key, value) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    var separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return uri + separator + key + '=' + value;
    }
  }
  onUploaded(e: any, cellInfo: any): void {
    //    //console.log("aqui", e  .request.responseText);
    $('.dx-fileuploader-file-info').hide();

    setTimeout(() => {
      $('.dx-fileuploader-file-status-message').hide();
      $('.dx-fileuploader-upload-button').hide();
    }, 500);
    const result = JSON.parse(e.request.responseText);
    ////console.log(result.path);
    cellInfo.setValue(result.path);
    this.retryButtonVisible = false;
  }

  onUploadError(e: any): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = 'Connection refused';
    }
    this.retryButtonVisible = true;
  }
expandGrid() {
        const gridContainer = this.dataGridContainer.nativeElement;
        const isExpanded = gridContainer.classList.toggle('expanded');

        if (isExpanded) {
            this.renderer.setStyle(gridContainer, 'position', 'fixed');
            this.renderer.setStyle(gridContainer, 'top', '0');
            this.renderer.setStyle(gridContainer, 'left', '0');
            this.renderer.setStyle(gridContainer, 'width', '100%');
            this.renderer.setStyle(gridContainer, 'height', '100%');
            this.renderer.setStyle(gridContainer, 'z-index', '1000');
            this.renderer.setStyle(gridContainer, 'background-color', 'white');
        } else {
            this.renderer.removeStyle(gridContainer, 'position');
            this.renderer.removeStyle(gridContainer, 'top');
            this.renderer.removeStyle(gridContainer, 'left');
            this.renderer.removeStyle(gridContainer, 'width');
            this.renderer.removeStyle(gridContainer, 'height');
            this.renderer.removeStyle(gridContainer, 'z-index');
            this.renderer.removeStyle(gridContainer, 'background-color');
        }
    
    
        
    }

  onToolbarPreparing(e) {
   const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
        location: 'after',
        widget: 'dxButton',
        options: {
            icon: 'fullscreen', // Ícone de tela cheia
            hint: 'Expand Grid',
            onClick: this.expandGrid.bind(this)
        }
    });


    if(!this.print) return;
    const t = this;


    /*e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'verticalaligntop',
        hint: 'Folha de rosto',    
        onClick: async () => {
          const filterValue = e.component.option('filterValue');
          const dataSource = e.component.getDataSource();
        //  console.log("datasource", dataSource.items()); // log the new dataSource object to the console
          dataSource.load({ filter: filterValue });
        
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            dataSource: dataSource.items()
          };
          dialogConfig.width = '750px';
          dialogConfig.height = '750px';
          dialogConfig.position = { top: '60px', left:'35%'};
          dialogConfig.panelClass = 'print-dialog';
          const dialogRef = this.dialog.open(FaceSheetComponent, dialogConfig);
          await dialogRef.afterClosed().toPromise();
        }
      }
    }); */
  }
  async openModal(list:any[]) {
    //this.modalService.open(this.modalContent, { centered: true });
  }


}
