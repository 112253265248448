<div #dataGridContainer class="grid-container">
<dx-chart
  id="{{idChart}}"
  palette="{{palette}}"
  [dataSource]="dataSource"
  title="{{title}}"
>
  <dxi-series valueField="fp" name="FP" color="{{fpColor}}"></dxi-series>
  <dxi-series valueField="fn" name="FN" color="{{fnColor}}" ></dxi-series>
  <dxi-series valueField="tn" name="TN" color="{{tnColor}}"></dxi-series>
  <dxi-series valueField="tp" name="TP" color="{{tpColor}}"></dxi-series>
  <dxi-series axis="total" type="{{totalType}}" valueField="total" name="Total" color="{{totalColor}}"></dxi-series>
  
  <dxo-common-series-settings argumentField="score" type="fullstackedbar">
  </dxo-common-series-settings>
  <dxi-value-axis>
    <dxo-grid [visible]="true"></dxo-grid>
  </dxi-value-axis>
  <dxi-value-axis name="total" position="right">
    <dxo-grid [visible]="true"></dxo-grid>
    <dxo-title text="{{axisTitle}}"></dxo-title>
  </dxi-value-axis>
  <dxo-legend verticalAlignment="{{verticalLegend}}" horizontalAlignment="{{horizontalLegend}}">
  </dxo-legend>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-tooltip
    [enabled]="true"
    [shared]="true"
    [customizeTooltip]="customizeTooltip"
  >
    <dxo-format type="largeNumber" [precision]="1"></dxo-format>
  </dxo-tooltip>
</dx-chart>

</div>
