<div #dataGridContainer class="grid-container">
<dx-data-grid
  #targetDataGrid
  id="{{ gridId }}"
  [column]="grid"
  [cacheEnabled]="false"
  keyExpr="{{ id || 'id' }}"
  [repaintChangesOnly]="true"
  [dataSource]="dataSource"
  [allowColumnReordering]="true"
  [showBorders]="true"
  [hoverStateEnabled]="true"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onEditingStart)="onEditingStart($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onEditorPrepared)="prepared($event)"
  (onContentReady)="onContentReady($event)"
  (onInitNewRow)="onInitNewRow($event)"
  (onRowValidating)="onRowValidating($event)"
  (onRowInserting)="onRowInserting($event)"
  (onRowInserted)="logEvent('insert', $event)"
  (onRowUpdating)="onRowUpdating($event)"
  (onCellPrepared)="onCellPrepared($event)"
  (onRowUpdated)="logEvent('update', $event)"
  (onRowRemoving)="onRowRemoving($event); empty($event)"
  (onRowClick)="show($event)"
  (onRowRemoved)="logEvent('delete', $event)"
  (baseEmit)="baseEmit($event)"
  [showColumnLines]="false"
  [showRowLines]="false"
  [showBorders]="true"
  [rowAlternationEnabled]="false"
  [autoNavigateToFocusedRow]="hasNavigation"
  [focusedRowEnabled]="focusedRowEnabled"
  [focusedRowIndex]="focusedRowIndex"
  [allowColumnResizing]="true"
  [columnAutoWidth]="false"
  (onEditCanceled)="displayVisible($event)"
  (onSaved)="displayVisible($event)"
  
  [width]="'100%'"
  [scrolling]="{ mode: 'virtual' }"
>

  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
  <!-- [focusedRowEnabled]="true"
  [focusedRowIndex]="focusedRowIndex" [pageIndex]="pageIndex" -->

  <!-- <dxo-selection mode="single"></dxo-selection> -->
  <dxo-paging [enabled]="true" [pageIndex]="pageIndex"></dxo-paging>
  <dxo-pager
    [visible]="true"
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[10, 20, 50, 100]"
    [showNavigationButtons]="true"
    [showInfo]="true"
    infoText="Page {0} of {1} ({2} items)"
  >
  </dxo-pager>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-editing mode="popup">
  <dxo-popup [showTitle]="true" [width]="'auto'" [height]="'auto'">
  </dxo-popup>
</dxo-editing>
  <dxo-editing
    mode="popup"
    
    [allowUpdating]="allowUpdating"
    [allowDeleting]="allowDeleting"
    [allowAdding]="allowAdding"
    [useIcons]="true"
  >
    <dxo-form [customizeItem]="customizeItem"></dxo-form>
   
     
  </dxo-editing>
  <dxi-column type="buttons" [width]="110">
    <dxi-button name="edit"></dxi-button>
    <dxi-button name="delete"></dxi-button>
    <dxi-button [cellTemplate]="chartIconTemplate"></dxi-button>
    <dxi-button
      hint="View details"
      icon="{{customIcon}}"
      [visible]="allowDetail"
      [onClick]="viewData"
    >
  </dxi-button> 

  
  <dx-data-grid [dataSource]="yourDynamicDataSource">
    <!-- Other column definitions go here... -->
  
    <dxi-column type="buttons" [width]="110">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
      <dxi-button [cellTemplate]="chartButtonTemplate"></dxi-button>
    </dxi-column>
  </dx-data-grid>

  </dxi-column>
  <dxo-export [enabled]="true"></dxo-export>
  <ng-container>
    <!--<dxi-column dataField="filename" [width]="80" [allowFiltering]=" false" [allowSorting]="false"
      cellTemplate="cellTemplate" editCellTemplate="editCellTemplate">
    </dxi-column>-->
    
    <dxo-scrolling mode="standard" rowRenderingMode="standard"></dxo-scrolling>
    <dxi-column
      *ngFor="let c of grid"
      dataField="{{ c.dataField }}"
      caption="{{ UPPER(c.caption) }}"
      [width]="c.width"
      [maxLength]="c.maxLength"
      [disabled]="c.disabled || false"
      dataType="{{ c.dataType }}"
      [format]="c.format"
      [(visible)]="c.visible"
      [validationRules]="c.validatorRules"
      [allowFiltering]="
        checkUndefined(c.allowFiltering) ? c.allowFiltering : true
      "
      [allowSorting]="checkUndefined(c.allowSorting) ? c.allowSorting : true"
      [cellTemplate]="c.cellTemplate || ''"
      [editCellTemplate]="c.editCellTemplate || ''"
    >
      <dxo-validation-rules>
        <dxo-validation-rule
          *ngFor="let rule of c.validationRules"
          [type]="rule.type"
          [message]="rule.message"
          [validationCallback]="
            parseValidationCallback(rule.validationCallback)
          "
        >
        </dxo-validation-rule>
      </dxo-validation-rules>

      <dxo-lookup
        *ngIf="checkUndefined(c.lookup)"
        [dataSource]="c.lookup.dataSource.result || c.lookup.dataSource"
        displayExpr="{{ c.lookup.displayExpr }}"
        valueExpr="{{ c.lookup.valueExpr }}"
      >
      </dxo-lookup>
    </dxi-column>
  </ng-container>

    <div *dxTemplate="let data of 'cellTemplate'">
    <img [src]="open(data.value)" />
  </div>
  
</dx-data-grid>
</div>
<ng-template #chartIconTemplate let-data="data">
  <span *ngIf="shouldShowChartIcon(data)">
    <i class="dx-icon dx-icon-chart"></i>
  </span>
</ng-template>

<dx-popup
  [width]="300"
  [height]="250"
  [showTitle]="true"
  title="Information"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="popupVisible"
>
  <div
    class="
      dx-form-validation-summary dx-validationsummary dx-widget dx-collection
    "
  >
    <div
      *ngFor="let error of brokenRules"
      class="dx-item dx-validationsummary-item"
    >
      <div class="dx-item-content dx-validationsummary-item-content">
        {{ error.message }}
      </div>
    </div>
  </div>
</dx-popup>


