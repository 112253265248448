<h2>{{ data.title }}</h2>
<div class="container">
  <div class="select-box">
    <h3>Available</h3>
    <select [(ngModel)]="availableSelected" multiple>
      <option *ngFor="let model of availableModels" [ngValue]="model">
        {{ model.name }}
      </option>
    </select>
  </div>
  <div class="buttons">
    <button class="btn btn-success
              font-weight-bold
              text-uppercase
              px-9
              py-4
              " (click)="moveToSelected()"> &gt; </button>
    <button class="btn btn-success
              font-weight-bold
              text-uppercase
              px-9
              py-4
              " (click)="moveToAvailable()"> &lt; </button>
  </div>
  <div class="select-box">
    <h3>Selected</h3>
    <select [(ngModel)]="selectedSelected" multiple>
      <option *ngFor="let model of selectedModels" [ngValue]="model">
        {{ model.name }}
      </option>
    </select>
  </div>
</div>
<div class="actions">
<button class="btn btn-success
              font-weight-bold
              text-uppercase
              px-9
              py-4
              left-button" (click)="onOkClick()">OK</button>
  <button class="btn btn-success
              font-weight-bold
              text-uppercase
              px-9
              py-4
              right-button" (click)="onCancelClick()">Cancel</button>
  
</div>
