      import {
        HashLocationStrategy,
        LocationStrategy,
        PathLocationStrategy,
      } from '@angular/common';
      import { NgModule } from '@angular/core';
      import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
      import { AuthGuard2 } from './guard/auth-guard';
      import { AuthGuard } from './modules/auth/_services/auth.guard';
      import { LayoutComponent } from './pages/_layout/layout.component';
      
      const form = import('./pages/form/form.module').then((m) => m.FormModule);
      
      export const routes: Routes = [
        {
          path: 'auth',
      
          loadChildren: () =>
            import('./modules/auth/auth.module').then((m) => m.AuthModule),
        },
      
        {
          path: '',
      
          component: LayoutComponent,
          canActivate: [AuthGuard2],
          runGuardsAndResolvers: 'always',
          children: [
            /*{
          path: 'wizard',
          component: LayoutComponent,
          loadChildren: () =>
            import('./pages/proccess-wizard/proccess-wizard.module').then(
              (m) => m.ProccessWizardModule
            ),
        },*/
            {
              path: 'people',
              // component: LayoutComponent,
              loadChildren: () =>
                import('./pages/people/people.module').then((m) => m.PeopleModule),
            },
      
            {
              path: 'base/:page',
      
              //component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/base/base.module').then((m) => m.BaseModule),
      
              // loadChildren: "./pages/base/base.module#BaseModule",
      
              /* loadChildren: () =>
      
            import("../app/pages/base/base.module").then(
      
              m => m.BaseModule
      
            ) */
            },
            {
              path: 'absenteeism',
      
              //component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/absenteeism/absenteeism.module').then(
                  (m) => m.AbsenteeismModule
                ),
      
              // loadChildren: "./pages/base/base.module#BaseModule",
      
              /* loadChildren: () =>
      
            import("../app/pages/base/base.module").then(
      
              m => m.BaseModule
      
            ) */
            },
      
            {
              path: 'absenteeism/:id',
      
              //component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/absenteeism/edit/absenteeism.module').then(
                  (m) => m.AbsenteeismModule
                ),
      
              // loadChildren: "./pages/base/base.module#BaseModule",
      
              /* loadChildren: () =>
      
            import("../app/pages/base/base.module").then(
      
              m => m.BaseModule
      
            ) */
            },
            {
              path: 'process-output/report/:page/:param',
      
              //component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/process/process-output/report/report.module').then(
                  (m) => m.ReportModule
                ),
      
              // loadChildren: "./pages/base/base.module#BaseModule",
      
              /* loadChildren: () =>
      
            import("../app/pages/base/base.module").then(
      
              m => m.BaseModule
      
            ) */
            },
            {
              path: 'form/:page/:id',
      
              // component: LayoutComponent,
      
              loadChildren: () => form,
            },
            {
              path: 'dashboard',
      
              loadChildren: () =>
                import('./pages/dashboard/dashboard.module').then(
                  (m) => m.DashboardModule
                ),
            },
            {
              path: 'form/:page',
      
              //   component: LayoutComponent,
      
              loadChildren: () => form,
            },
      
            {
              path: 'rule/:id',
      
              // component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/rules/rules.module').then((m) => m.RulesModule),
      
              outlet: 'sidebar',
            },
            {
              path: 'process-output/:param',
              loadChildren: () =>
                import('./pages/process/process-output/process-output.module').then(
                  (m) => m.ProcessOutputModule
                ),
            },
            {
              path: 'settingssegsocial',
              loadChildren: () =>
                import('./pages/settings-seg-social/settings-seg-social.module').then(
                  (m) => m.SettingsSegSocialModule
                ),
            },
            {
              path: 'process-output/invoice/:param',
              loadChildren: () =>
                import('./pages/process/process-output/invoice/invoice.module').then(
                  (m) => m.InvoiceModule
                ),
            },
            {
              path: 'map-discount-allowance/:param',
              loadChildren: () =>
                import(
                  './pages/process/process-output/map-discount-allowance/map-discount-allowance.module'
                ).then((m) => m.MapDiscountAllowanceModule),
              // outlet: 'sidebar',
            },
            {
              path: 'map-discount-allowance-list/:param',
              loadChildren: () =>
                import(
                  './pages/process/process-output/map-discount-allowance-list/map-discount-allowance-list.module'
                ).then((m) => m.MapDiscountAllowanceListModule),
              // outlet: 'sidebar',
            },
            {
              path: 'domainvalues',
      
              // component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/domain-values/domain-values.module').then(
                  (m) => m.DomainValuesModule
                ),
            },
            {
              path: 'profile',
              //component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/profile/profile.module').then((m) => m.ProfileModule),
            },
            {
              path: 'people/new-person',
              //component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/people/newperson/newperson.module').then(
                  (m) => m.NewPersonModule
                ),
            },
      
            {
              path: 'people/new-admission',
              //component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/people/newadmission/newadmission.module').then(
                  (m) => m.NewAdmissionModule
                ),
            },
            {
              path: 'config/roles-table/:id',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import(
                  './pages/config/permissions/roles-table/roles-table.module'
                ).then((m) => m.RolesTableModule),
            },
            {
              path: 'config/roles/new',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/config/permissions/roles/roles.module').then(
                  (m) => m.RolesModule
                ),
            },
            {
              path: 'config/roles/:id',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/config/permissions/roles/roles.module').then(
                  (m) => m.RolesModule
                ),
            },
            {
              path: 'config/groups/new',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/config/permissions/groups/groups.module').then(
                  (m) => m.GroupsModule
                ),
            },
            {
              path: 'config/groups/:id',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/config/permissions/groups/groups.module').then(
                  (m) => m.GroupsModule
                ),
            },
            {
              path: 'config/users/new',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/config/permissions/user/user.module').then(
                  (m) => m.UserModule
                ),
            },
            {
              path: 'config/users/:id',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/config/permissions/user/user.module').then(
                  (m) => m.UserModule
                ),
            },
            { 
              path: 'payment',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/payment/payment.module').then(
                  (m) => m.PaymentModule
                ),
            },

{ 
              path: 'manual/about',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/manual/about/about.module').then(
                  (m) => m.AboutModule
                ),
            },
            {
            path: 'manual/platform-features',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/manual/platform-features/platform-features.module').then(
                  (m) => m.PlataformFeaturesModule
                ),
            },
            {
            path: 'manual/dataset-construction',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/manual/dataset-construction/dataset-construction.module').then(
                  (m) => m.DatasetConstructionModule
                ),
            },
              {
            path: 'manual/model-analysis',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/manual/model-analysis/model-analysis.module').then(
                  (m) => m.ModelAnalysisModule
                ),
            },
            {
            path: 'manual/model-generation',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/manual/model-generation/model-generation.module').then(
                  (m) => m.ModelGenerationModule
                ),
            },
            {
            path: 'manual/model-predictions',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/manual/model-predictions/model-predictions.module').then(
                  (m) => m.ModelPredictionsModule
                ),
            },
            {
            path: 'manual/citations',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/manual/citations/citations.module').then(
                  (m) => m.CitationsModule
                ),
            },
            { 
              path: 'subscription/plan/:id',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/subscription/plan/plan.module').then(
                  (m) => m.PlanModule
                ),
            },
            { 
              path: 'model/detail/:id',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/model/detail/detail.module').then(
                  (m) => m.DetailModule
                ),
            },
            { 
              path: 'tools/rocplot',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/tools/rocplot/rocplot.module').then(
                  (m) => m.RocplotModule
                ),
            },
            { 
              path: 'tools/fdranalyser',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/tools/fdranalyser/fdranalyser.module').then(
                  (m) => m.FDRanalyserModule
                ),
            },
            { 
              path: 'tools/o2pheno',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/tools/o2pheno/o2pheno.module').then(
                  (m) => m.O2PhenoModule
                ),
            },
            
            { 
              path: 'dataset/new',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/modules/OIMSgen/dataset/dataset.module').then(
                  (m) => m.DatasetModule
                ),
            },
            { 
              path: 'predictor/single/:id',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/portal/modules/OIMSgen/predictor/single/single.module').then(
                  (m) => m.SingleModule
                ),
            },
            {
              path: 'workflow/expense',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/workflow/expense/expense.module').then(
                  (m) => m.ExpenseModule
                ),
            },
            {
              path: 'workflow/expense/:id',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/workflow/expense/expense.module').then(
                  (m) => m.ExpenseModule
                ),
            },
            {
              path: 'workflow/timeline',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/workflow/timeline/timeline.module').then(
                  (m) => m.TimelineModule
                ),
            },
            {
              path: 'expense/approval/:id',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/workflow/expense/approval/approval.module').then(
                  (m) => m.ApprovalModule
                ),
            },
            {
              path: 'expense/history/:id',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/workflow/expense/history/history.module').then(
                  (m) => m.HistoryModule
                ),
            },
            {
              path: 'vacation/periods',
              //component: LayoutComponent,
              runGuardsAndResolvers: 'always',
              loadChildren: () =>
                import('./pages/workflow/vacation/periods/periods.module').then(
                  (m) => m.PeriodsModule
                ),
            },
            {
              path: 'process',
      
              //component: LayoutComponent,
      
              //  loadChildren: () =>
      
              // import("./pages/process/process.module").then((m) => m.ProcessModule),
      
              //  loadChildren: './pages/process/process.module#ProcessModule',
              loadChildren: () =>
                import('./pages/process/process.module').then((m) => m.ProcessModule),
            },
      
            {
              path: 'process/:id',
      
              // component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/process-details/process-details.module').then(
                  (m) => m.ProcessDetailsModule
                ),
            },
      
            {
              path: 'data-management',
      
              // component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/data-management/data-management.module').then(
                  //     import('./pages/proccess-wizard/step3/step3.module').then(
                  (m) => m.DataManagementModule
                ),
            },
      
            {
              path: 'btransfer',
              loadChildren: () =>
                import('./pages/btransfer/btransfer.module').then(
                  (m) => m.BTransferModule
                ),
              data: {
                breadcrumb: 'Wizard',
              },
            },
      
            {
              path: 'genomic-wizard',
              loadChildren: () =>
                import('./pages/model-wizard/model-wizard.module').then(
                  (m) => m.ModelWizardModule
                ),
              data: {
                breadcrumb: 'Wizard',
              },
            },
           
      
            {
              path: 'bank-transfer/:param',
      
              //   component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/bank-transfer/bank-transfer.module').then(
                  (m) => m.BankTransferModule
                ),
            },
            {
              path: 'process-output/reports/segsocial/:param',
      
              //  component: LayoutComponent,
      
              loadChildren: () =>
                import(
                  './pages/process/process-output/seguranca-social/seguranca-social.module'
                ).then((m) => m.SegurancaSocialModule),
            },
            {
              path: 'process-output/reports/dmr/:param',
      
              //  component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/process/process-output/dmr/dmr.module').then(
                  (m) => m.DMRModule
                ),
            },
            {
              path: 'process-output/sepa/:param',
      
              //  component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/process/process-output/sepa/sepa.module').then(
                  (m) => m.SepaModule
                ),
            },
      
            {
              path: 'process-paycheck',
      
              // component: LayoutComponent,
      
              loadChildren: () =>
                import('./pages/process-paycheck/process-paycheck.module').then(
                  (m) => m.ProcessPaycheckModule
                ),
            },
      
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          ],
        },
        {
          path: 'error',
      
          loadChildren: () =>
            import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
        },
      
        {
          path: '',
      
          canActivate: [AuthGuard2],
          runGuardsAndResolvers: 'always',
          loadChildren: () =>
            import('./pages/layout.module').then((m) => m.LayoutModule),
        },
      
        { path: '**', redirectTo: 'error/404' },
      ];
      
      @NgModule({
        imports: [
          RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            useHash: false,
            scrollPositionRestoration: 'top',
            relativeLinkResolution: 'legacy',
            onSameUrlNavigation: 'reload',
          }),
        ],
        providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
        exports: [RouterModule],
      })
      export class AppRoutingModule {}
      