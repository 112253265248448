import { Component, Input } from '@angular/core';

@Component({
  selector: 'kt-roc',
  templateUrl: './roc.component.html',
  styleUrls: ['./roc.component.scss'],
})
export class RocComponent {
  @Input() datasource: any[];
  @Input() title: string = "";

  chartOptions: any = {
    // ...other chart configuration options...
    argumentAxis: {
      title: '1 - Specificity(%)' // Add title to x-axis
    },
    valueAxis: {
      title: 'Sensitivity(%)' // Add title to y-axis
    }
  };
}